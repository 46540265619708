import api from "@/api"

export const actions = {
    GetUnreadedMessages: ({ commit }) => {
        api.get('/chats/messages/unreaded')
        .then(response => {
            const { body, type } = response.data;

            if(type == 'success') {
                commit('SET_UNREADED_MESSAGES', body);
            }
        })
    },
}
