<template>
    <b-toast
        variant="info"
        title="Importação automática"
        solid
        v-model="visible"
        no-auto-hide
        is-status
        append-toast
    >
        <p class="mb-2">{{ message }}</p>
        <b-progress variant="dark" :value="processed" :max="total"></b-progress>
        <div class="d-flex align-items-center justify-content-between">
            <small>{{ processingMessage }}</small>
            <small>{{ processed }}/{{ total }}</small>
        </div>
        <div
            v-if="processed >= total"
            class="d-flex align-items-center justify-content-end mt-2"
        >
            <button
                @click="visible = false"
                class="btn mx-2 btn-sm btn-secondary"
            >
                Ignorar
            </button>
            <button @click="goToReview" class="btn btn-sm btn-success">
                Revisar
            </button>
        </div>
    </b-toast>
</template>

<script>
import { BToast, BProgress } from "bootstrap-vue";
export default {
    components: {
        BToast,
        BProgress,
    },
    props: {
        batchId: {
            type: String,
        },
        form: {
            type: String,
            default: () => "Padrão",
        },
        size: {
            type: Number,
            default: () => 0,
        },
    },
    data() {
        return {
            visible: true,
            total: this.$props.size,
            processed: 0,
            name: this.$props.form,
            channel: null,
        };
    },
    methods: {
        async subscribeImportChanel() {
            this.channel = await this.$store.dispatch("ws/ListenEventChannel", {
                channel: `imports.customers.${this.batchId}`,
                event: "ImportCustomerProgressUpdated",
                func: ({ total_count, processed_count }) => {
                    this.processed = processed_count;
                    this.total = total_count;
                },
                onSubscribe: () => (this.visible = true),
            });
        },
        goToReview() {
            this.visible = false;
            this.$router.replace({
                name: "register/review",
                params: { id: this.batchId },
            });
        },
    },
    computed: {
        processingMessage() {
            return this.processed < this.total
                ? "Processando..."
                : "Processado";
        },
        message() {
            return this.processed < this.total
                ? `Importando clientes para o formulário ${this.name}...`
                : `Importação para o formulário ${this.name} concluída.`;
        },
    },
    created() {
        this.subscribeImportChanel();
    },
    watch: {
        batchId(val) {
            if (val != null) this.subscribeImportChanel();
        },
        "$store.getters.ws.connected"(val) {
            if (val && this.$props.batchId != null)
                this.subscribeImportChanel();
        },
        visible(val) {
            if (!val && this.channel) this.channel.unsubscribe();
        },
    },
};
</script>
