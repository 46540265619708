export const macroList = [
	{
		name: "Nome do Cliente",
		neddle: "%%Cliente%%",
		color: "#3ab5a0",
		regex: "(%%Cliente%%)",
		description: "Pega o nome do cliente a qual a campanha está sendo disparada."
	},
	{
		name: "Nome do Atendente",
		neddle: "%%Atendente%%",
		color: "#df6868",
		regex: "(%%Atendente%%)",
		description: "Pega o nome do usuário responsável pelo número de disparo."
	},
	{
		name: "Nome da Campanha",
		neddle: "%%Campanha%%",
		color: "#822E81",
		regex: "(%%Campanha%%)",
		description: "Pega o nome da campanha."
	},
	{
		name: "Campo do Formulário",
		neddle: "%%Formulario:campo,padrao%%",
		color: "#5ecbdd",
		modal: true,
		regex: "(%%Formulario:[\\w\\sÀ-ÿ]{1,},?[\\w\\sÀ-ÿ]{1,}?%%)",
		description:
			"Pega o valor de um campo do formulário de cliente. O valor 'padrao' é opcional."
	}
];

export const integrationMacroList = [
	{
		name: "Nome do Cliente",
		neddle: "%%Cliente%%",
		color: "#3ab5a0",
		regex: "(%%Cliente%%)",
		description: "Pega o nome do cliente que realizou o pedido."
	},
	{
		name: "Número do Pedido",
		neddle: "%%Pedido%%",
		color: "#df6868",
		regex: "(%%Pedido%%)",
		description: "Pega o número único do pedido."
	},
	{
		name: "Total",
		neddle: "%%Total%%",
		color: "#822E81",
		regex: "(%%Total%%)",
		description: "Pega o total do valor gasto no pedido."
	},
	{
		name: "Método de Pagamento",
		neddle: "%%MetodoDePagamento%%",
		color: "#5ecbdd",
		regex: "(%%MetodoDePagamento%%)",
		description: "Pega o método de pagamento utilizado no pedido."
	},
	{
		name: "Endereço de Envio",
		neddle: "%%Endereco%%",
		color: "#317F98",
		regex: "(%%Endereco%%)",
		description: "Pega o endereço de envio cadastrado no pedido."
	},
	{
		name: "Código de Rastreio",
		neddle: "%%CodigoRastreio%%",
		color: "#E88620",
		regex: "(%%CodigoRastreio%%)",
		description: "Pega o código de rastreio do envio cadastrado no pedido."
	}
];

export const defaultForm = [
	[
		{
			name: "Nome Completo",
			type: "name",
			value: "",
			required: true
		},
		{
			name: "Telefone",
			type: "main_phone",
			value: "",
			required: true
		}
	],
	{
		name: "Email",
		type: "email",
		value: "",
		required: true
	},
	{
		name: "Telefone 2",
		type: "text",
		value: "",
		required: false
	}
];

export const countries = {
	AF: "Afeganistão",
	ZA: "África do Sul",
	AX: "Aland, Ilhas",
	AL: "Albânia",
	DE: "Alemanha",
	AD: "Andorra",
	AO: "Angola",
	AI: "Anguila",
	AQ: "Antártida",
	AG: "Antígua e Barbuda",
	SA: "Arábia Saudita",
	DZ: "Argélia",
	AR: "Argentina",
	AM: "Armênia",
	AW: "Aruba",
	AU: "Austrália",
	AT: "Áustria",
	AZ: "Azerbaijão",
	BS: "Bahamas",
	BD: "Bangladesh",
	BB: "Barbados",
	BH: "Barém",
	BE: "Bélgica",
	BZ: "Belize",
	BJ: "Benim",
	BM: "Bermudas",
	BY: "Bielorrússia",
	BO: "Bolívia",
	BQ: "Bonaire, Santo Eustáquio e Saba",
	BA: "Bósnia e Herzegovina",
	BW: "Botsuana",
	BV: "Bouvet, Ilha",
	BR: "Brasil",
	BN: "Brunei",
	BG: "Bulgária",
	BF: "Burquina Fasso",
	BI: "Burundi",
	BT: "Butão",
	CV: "Cabo Verde",
	KY: "Caimã, Ilhas",
	KH: "Camboja",
	CM: "Camarões",
	CA: "Canadá",
	QA: "Catar",
	KZ: "Cazaquistão",
	CF: "Centro-Africana, República",
	TD: "Chade",
	CZ: "Chéquia",
	CL: "Chile",
	CN: "China",
	CY: "Chipre",
	CC: "Cocos, Ilhas",
	CO: "Colômbia",
	KM: "Comores",
	CG: "Congo, República do",
	CD: "Congo, República Democrática do (antigo Zaire)",
	CK: "Cook, Ilhas",
	KR: "Coreia do Sul",
	KP: "Coreia, República Democrática da (Coreia do Norte)",
	CI: "Costa do Marfim",
	CR: "Costa Rica",
	HR: "Croácia",
	CU: "Cuba",
	CW: "Curaçau",
	DK: "Dinamarca",
	DJ: "Djibuti",
	DM: "Dominica",
	DO: "Dominicana, República",
	EG: "Egito",
	SV: "El Salvador",
	AE: "Emirados Árabes Unidos",
	EC: "Equador",
	ER: "Eritreia",
	SK: "Eslováquia",
	SI: "Eslovênia",
	ES: "Espanha",
	US: "Estados Unidos",
	EE: "Estónia",
	SZ: "Essuatíni",
	SJ: "Esvalbarde e Jan Mayen",
	ET: "Etiópia",
	FO: "Feroé, Ilhas",
	FJ: "Fiji",
	PH: "Filipinas",
	FI: "Finlândia",
	FR: "França",
	GA: "Gabão",
	GM: "Gâmbia",
	GH: "Gana",
	GE: "Geórgia",
	GS: "Geórgia do Sul e Sanduíche do Sul, Ilhas",
	GI: "Gibraltar",
	GD: "Granada",
	GR: "Grécia",
	GL: "Groenlândia",
	GP: "Guadalupe",
	GU: "Guam",
	GT: "Guatemala",
	GG: "Guernsey",
	GY: "Guiana",
	GF: "Guiana Francesa",
	GW: "Guiné-Bissau",
	GN: "Guiné-Conacri",
	GQ: "Guiné Equatorial",
	HT: "Haiti",
	HM: "Heard e Ilhas McDonald, Ilha",
	HN: "Honduras",
	HK: "Hong Kong",
	HU: "Hungria",
	YE: "Iémen",
	IN: "Índia",
	ID: "Indonésia",
	IQ: "Iraque",
	IR: "Irã",
	IE: "Irlanda",
	IS: "Islândia",
	IL: "Israel",
	IT: "Itália",
	JM: "Jamaica",
	JP: "Japão",
	JE: "Jersey",
	JO: "Jordânia",
	KW: "Kuwait",
	LA: "Laos",
	LS: "Lesoto",
	LV: "Letônia",
	LB: "Líbano",
	LR: "Libéria",
	LY: "Líbia",
	LI: "Listenstaine",
	LT: "Lituânia",
	LU: "Luxemburgo",
	MO: "Macau",
	MK: "Macedônia do Norte",
	MG: "Madagáscar",
	YT: "Maiote	França",
	MY: "Malásia",
	MW: "Maláui",
	MV: "Maldivas",
	ML: "Mali",
	MT: "Malta",
	FK: "Malvinas, Ilhas (Falkland)",
	IM: "Man, Ilha de",
	MP: "Marianas Setentrionais",
	MA: "Marrocos",
	MH: "Marshall, Ilhas",
	MQ: "Martinica",
	MU: "Maurícia",
	MR: "Mauritânia",
	UM: "Menores Distantes dos Estados Unidos, Ilhas",
	MX: "México",
	MM: "Mianmar",
	FM: "Micronésia, Estados Federados da",
	MZ: "Moçambique",
	MD: "Moldávia",
	MC: "Mônaco",
	MN: "Mongólia",
	MS: "Monserrate",
	ME: "Montenegro",
	NA: "Namíbia",
	CX: "Natal, Ilha do (Ilha Christmas)",
	NR: "Nauru",
	NP: "Nepal",
	NI: "Nicarágua",
	NE: "Níger",
	NG: "Nigéria",
	NU: "Niue",
	NF: "Norfolque, Ilha",
	NO: "Noruega",
	NC: "Nova Caledônia",
	NZ: "Nova Zelândia (Aotearoa)",
	OM: "Omã",
	NL: "Países Baixos (Holanda)",
	PW: "Palau",
	PS: "Palestina",
	PA: "Panamá",
	PG: "Papua-Nova Guiné",
	PK: "Paquistão",
	PY: "Paraguai",
	PE: "Peru",
	PN: "Pitcairn",
	PF: "Polinésia Francesa",
	PL: "Polônia",
	PR: "Porto Rico",
	PT: "Portugal",
	KE: "Quênia",
	KG: "Quirguistão",
	KI: "Quiribáti",
	GB: "Reino Unido da Grã-Bretanha e Irlanda do Norte",
	RE: "Reunião",
	RO: "Romênia",
	RW: "Ruanda",
	RU: "Rússia",
	EH: "Saara Ocidental",
	AS: "Samoa Americana",
	WS: "Samoa (Samoa Ocidental)",
	SB: "Salomão, Ilhas",
	SM: "San Marino",
	SH: "Santa Helena",
	LC: "Santa Lúcia",
	BL: "São Bartolomeu",
	KN: "São Cristóvão e Neves",
	MF: "São Martinho (França)",
	SX: "São Martinho (Países Baixos)",
	PM: "São Pedro e Miquelão",
	ST: "São Tomé e Príncipe",
	VC: "São Vicente e Granadinas",
	SC: "Seicheles",
	SN: "Senegal",
	LK: "Sri Lanka",
	SL: "Serra Leoa",
	RS: "Sérvia",
	SG: "Singapura",
	SY: "Síria",
	SO: "Somália",
	SD: "Sudão",
	SS: "Sudão do Sul",
	SE: "Suécia",
	CH: "Suíça",
	SR: "Suriname",
	TH: "Tailândia",
	TW: "Taiwan",
	TJ: "Tajiquistão",
	TZ: "Tanzânia",
	TF: "Terras Austrais e Antárticas Francesas (TAAF)",
	IO: "Território Britânico do Oceano Índico",
	TL: "Timor-Leste",
	TG: "Togo",
	TO: "Tonga",
	TK: "Toquelau",
	TT: "Trindade e Tobago",
	TN: "Tunísia",
	TC: "Turcas e Caicos",
	TM: "Turcomenistão",
	TR: "Turquia",
	TV: "Tuvalu",
	UA: "Ucrânia",
	UG: "Uganda",
	UY: "Uruguai",
	UZ: "Usbequistão",
	VU: "Vanuatu",
	VA: "Vaticano",
	VE: "Venezuela",
	VN: "Vietnã",
	VI: "Virgens Americanas, Ilhas",
	VG: "Virgens Britânicas, Ilhas",
	WF: "Wallis e Futuna",
	ZM: "Zâmbia",
	ZW: "Zimbábue"
};

export const states = {
	AC: "Acre",
	AL: "Alagoas",
	AP: "Amapá",
	AM: "Amazonas",
	BA: "Bahia",
	CE: "Ceará",
	ES: "Espírito Santo",
	GO: "Goiás",
	MA: "Maranhão",
	MT: "Mato Grosso",
	MS: "Mato Grosso do Sul",
	MG: "Minas Gerais",
	PA: "Pará",
	PB: "Paraíba",
	PR: "Paraná",
	PE: "Pernambuco",
	PI: "Piauí",
	RJ: "Rio de Janeiro",
	RN: "Rio Grande do Norte",
	RS: "Rio Grande do Sul",
	RO: "Rondônia",
	RR: "Roraima",
	SC: "Santa Catarina	",
	SP: "São Paulo",
	SE: "Sergipe",
	TO: "Tocantins",
	DF: "Distrito Federal"
};

export const acceptedMimes = {
	image: ["jpg", "png", "jpeg"],
	video: ["mp4", "3gpp"],
	document: ["pdf"]
};

export const acceptedFieldNameRegex = /^[0-9A-zÀ-ú-_\s]+$/;

export const statuses = {
	connected: "Conectado",
	disconnected: "Desconectado",
	expired: "Expirado"
};

export const currencyFormatter = Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL', style: 'currency' });

export const isTrialDuration = 15;

export const planTypes = {
	monthly: "Mensal",
	quarterly: "Trimestral",
	semiannual: "Semestral",
	annual: "Anual"
};

export const planTypesOrdinal = {
	monthly: "mês",
	quarterly: "trimestre",
	semiannual: "semestre",
	annual: "ano"
};

export const planTypesSize = {
	monthly: 1,
	quarterly: 3,
	semiannual: 6,
	annual: 12
};

export const integrationFields = [
	"Nome do Formulário",
	"Integração ID",
	"Nome Completo",
	"Telefone",
	"Email",
	"Data de Nascimento",
	"Cidade",
	"Endereço",
	"CEP",
	"Data de Cadastro",
	"Data da Última Compra"
];