<template>
    <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
        menu-class="dropdown-menu-media"
    >
        <template #button-content>
            <b-avatar size="40" :src="userData.profile" variant="light-primary">
                <feather-icon
                    v-if="userData.profile == null"
                    icon="UserIcon"
                    size="22"
                />
            </b-avatar>
            <div class="d-sm-flex d-none user-nav">
                <p class="user-name font-weight-bolder mb-0">
                    {{ userData.name }}
                </p>
                <span class="user-status">
                    {{
                        userData.type == "owner"
                            ? "Proprietário"
                            : "Funcionário"
                    }}
                </span>
            </div>
        </template>

        <b-dropdown-item
            :to="{ name: 'settings', params: { tab: 'perfil' } }"
            link-class="d-flex align-items-center"
        >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Perfil</span>
        </b-dropdown-item>
        <b-dropdown-item
            link-class="d-flex align-items-center"
            @click.prevent="logout"
        >
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Sair</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BAvatar } from "bootstrap-vue";

export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BAvatar,
    },
    computed: {
        userData() {
            return this.$store.getters["user/get"];
        },
    },
    methods: {
        logout() {
            this.$store.dispatch("user/logout").then(() => {
                this.$router.replace({ name: "auth-login" });
            });
        },
    },
};
</script>
