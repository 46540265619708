export default [
    {
        path: "/entrar",
        name: "auth-login",
        component: () => import("@/views/pages/authentication/Login.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
            guest: true,
        },
    },
    {
        path: "/cadastrar",
        name: "auth-register",
        component: () => import("@/views/pages/authentication/Register.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
            guest: true,
        },
    },
    {
        path: "/esqueci-minha-senha",
        name: "auth-forgot-password",
        component: () =>
            import("@/views/pages/authentication/ForgotPassword.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
            guest: true,
        },
    },
    {
        path: "/redefinir-senha",
        name: "auth-reset-password",
        component: () =>
            import("@/views/pages/authentication/ResetPassword.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
            guest: true,
        },
    },
    {
        path: "/404",
        name: "error-404",
        component: () => import("@/views/pages/error/Error404.vue"),
        meta: {
            layout: "full",
            resource: "Auth",
            action: "read",
        },
    },
];
