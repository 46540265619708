export const mutations = {
    ADD_MESSAGE: (state, message) => {
        if(!message.from_me) state.unreadedMessages += 1;
        if(message.contact_id in state.messages) {
            state.messages[message.contact_id].push(message);
        } else {
            Object.assign(state.messages, Object.fromEntries([[message.contact_id, [message]]]));
        }
    },
    SET_UNREADED_MESSAGES: (state, val) => {
        Object.assign(state, { unreadedMessages: val });
    },
    REMOVE_CONTACT: (state, id) => {
        const index = state.contacts.findIndex(e => e._id == id);
        state.contacts.splice(index, 1);
    },
    ADD_CONTACTS: (state, contacts) => {
        const array = [...state.contacts, ...contacts];
        const arrayUniqueByKey = [...new Map(array.map(item => [item['_id'], item])).values()];
        Object.assign(state, { contacts: arrayUniqueByKey });
    },
    SET_CONTACTS: (state, contacts) => {
        const arrayUniqueByKey = [...new Map(contacts.map(item => [item['_id'], item])).values()];
        Object.assign(state, { contacts: arrayUniqueByKey });
    },
    SET_CONTACT: (state, contact) => {
        const index = state.contacts.findIndex(e => e._id == contact._id);
        if(index >= 0)
            return Object.assign(state.contacts[index], contact);

        if(contact.status != undefined)
            state.contacts.splice(0, 0, contact);
    },
}
