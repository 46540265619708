import api from "@/api";
import { Session } from "@/auth";

export const actions = {
    login({ commit }, credentials) {
        try {
            document.getElementById("loading-bg").style.display = "flex";
            const session = new Session(credentials.user, credentials.token, credentials.remember);
            session.save();
            commit('SET_SESSION', session);
        } catch (error) {
            console.log(error);
            commit('RESET');
        }
    },
    async logout({ commit }) {
        document.getElementById("loading-bg").style.display = "flex";
        await api.post('/users/logout');
        commit('RESET');
    },
    async loadSession({ commit }) {
        const session = new Session().load();
        if (session == null) return false;

        try {
            const response = await api.get('/me');

            const { type, body } = response.data;

            if (type == 'success') {
                // console.log(body)
                session.user = body.user;
                session.extraValues = body.extra_values;
                session.save();
                commit('SET_SESSION', session);
                return true;
            }
            commit('RESET');
            return false;
        } catch (error) {
            if (error.response && error.response.status == 401) {
                commit('RESET');
            }
            return false;
        }
    }
}
