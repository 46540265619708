<template>
    <div class="position-relative h-100">
        <Transition name="connection">
            <div v-if="showBackOnline" class="lost-connection-div bg-white d-flex flex-column align-items-center justify-content-center">
                <div class="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center text-center">
                    <span class="spinner-grow mb-4" style="width: 3rem; height: 3rem;" role="status"></span>
                    <h2>Conexão temporariamente perdida</h2>
                    <p>Você está offline no momento, mas não se preocupe! Verifique a sua conexão com a Internet enquanto tentamos reconectar você (pode levar alguns minutos).</p>
                    <a class="btn btn-primary" href="javascript:window.location.reload(true)">Recarregar</a>
                </div>
            </div>
        </Transition>
        <Transition name="connection">
            <div v-if="maintenanceMode" class="lost-connection-div bg-white d-flex flex-column align-items-center justify-content-center">
                <div class="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center text-center">
                    <font-awesome-icon style="font-size: 4rem; animation: hammered 900ms ease 100ms alternate infinite;" :icon="['fas', 'hammer']" class="text-primary mb-5" />
                    <h2>Atualização em Andamento</h2>
                    <p>Estamos passando por uma breve manutenção para aprimorar nosso sistema. Durante esse período, o servidor estará indisponível por alguns minutos. Agradecemos pela sua compreensão e garantimos que retornaremos em breve, proporcionando uma experiência ainda melhor. Agradecemos sua paciência.</p>
                    <a class="btn btn-primary" href="javascript:window.location.reload(true)">Recarregar</a>
                </div>
            </div>
        </Transition>
        <component :is="layout" class="main-container">
            <router-view/>
        </component>
    </div>
</template>

<script>
import AppLayout from '@/views/layouts/AppLayout/index.vue';
import AuthenticationLayout from '@/views/layouts/AuthenticationLayout/index.vue';

export default {
    components: {
        AppLayout,
        AuthenticationLayout
    },
    computed: {
        layout() {
            if (this.$store.getters['user/isAuthenticated']) return 'app-layout';
            return 'authentication-layout';
        },
        maintenanceMode() {
            return this.$store.getters['app/isMaintenanceMode'] ?? false;
        },
    },
    data() {
        return {
            online: navigator.onLine,
            showBackOnline: false
        }
    },
    methods: {
        updateOnlineStatus(e) {
            const { type } = e;
            this.online = type === 'online';
        }
    },
    watch: {
        online(v) {
            if (v)
                setTimeout(() => this.showBackOnline = false, 1000);
            else
                this.showBackOnline = true;
        }
    },
    mounted() {
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    }
};
</script>

<style lang="scss">
.main-container {
    font-family: DM Sans, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #999999;
    text-align: center;
}

.connection-enter-active,
.connection-leave-active {
  transition: opacity 0.5s ease;
}

.connection-enter-from,
.connection-leave-to {
  opacity: 0;
}

@keyframes hammered {
    0% {
        transform: rotate(20deg);
    }

    100% {
        transform: rotate(-20deg);
    }
}
</style>
