import { actions } from "./actions"
import { getters } from "./getters"
import { mutations } from "./mutations"

export const chatStoreModel = () => {
    return {
        contacts: [],
        messages: {},
        unreadedMessages: 0
    }
}

export default {
    namespaced: true,
    state: chatStoreModel(),
    getters: getters,
    actions: actions,
    mutations: mutations,
}
