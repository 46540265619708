export const mutations = {
    UPDATE_ROUTER_TRANSITION(state, val) {
        state.layout.routerTransition = val;
    },
    UPDATE_LAYOUT_TYPE(state, val) {
        state.layout.type = val;
    },
    UPDATE_CONTENT_WIDTH(state, val) {
        state.layout.contentWidth = val;
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
        state.layout.menu.hidden = val;
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
        Object.assign(state.layout.navbar, obj);
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
        Object.assign(state.layout.footer, obj);
    },
}
