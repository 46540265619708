import store from "@/store";
import moment from "moment";
import { statuses } from "./constants";

export const getStringTime = (time) => {
	try {
		const date = moment(time);
		const months = [
			"JAN",
			"FEV",
			"MAR",
			"ABR",
			"MAI",
			"JUN",
			"JUL",
			"AGO",
			"SET",
			"OUT",
			"NOV",
			"DEZ"
		];

		let dateString;

		if (date.isSame(moment(), "day")) {
			dateString = "Hoje";
		} else if (date.isSame(moment().subtract(1, "day"), "day")) {
			dateString = "Ontem";
		} else {
			dateString = `${date.date()} ${months[date.month()]} ${date.year()}`;
		}

		return `${dateString} ${date.format("HH:mm")}`;
	} catch (error) {
		return "";
	}
};

export const getShortStringTime = (time) => {
	try {
		const date = moment(time);

		if (date.isSame(moment(), "day")) {
			return date.format("HH:mm");
		} else if (date.isSame(moment(), "year")) {
			return date.format("DD/MM");
		}
		return date.format("DD/MM/YYYY");
	} catch (error) {
		return "";
	}
};

export const setTagsMessage = (text) => {
	text = text.replaceAll(/\*(\S|\S[^*]*\S)\*/g, "<strong>$1</strong>");
	text = text.replaceAll(/_(\S|\S[^_]*\S)_/g, "<em>$1</em>");
	text = text.replaceAll(/~(\S|\S[^~]*\S)~/g, "<s>$1</s>");
	text = text.replaceAll(/\n/g, "<br>");
	return text;
};

export const clearTagsMessage = (text) => {
	text = text.replace(/<[^>]*>?/gm, "");
	text = text.replaceAll(/\n/g, " ");
	text = text.replaceAll(/\*(\S|\S[^*]*\S)\*/g, "$1");
	text = text.replaceAll(/_(\S|\S[^_]*\S)_/g, "$1");
	text = text.replaceAll(/~(\S|\S[^~]*\S)~/g, "$1");
	return text.trim();
};

export const formatPhone = (text) => {
	text = String(text).replaceAll(/[^0-9]/g, "");
	const regex1 = /(\d{2})(\d{2})(\d{4,5})(\d{4})/g;
	const regex2 = /(\d{2})(\d{4,5})(\d{4})/g;
	const regex3 = /(\d{4,5})(\d{4})/g;
	if (regex1.test(text)) return text.replaceAll(regex1, "+$1 ($2) $3-$4");
	if (regex2.test(text)) return text.replaceAll(regex2, "($1) $2-$3");
	if (regex3.test(text)) return text.replaceAll(regex3, "$1-$2");
	return text;
};

/**
 * Verifica se o usuário logado pode acessar a rota que depende de campanha.
 * 
 * @param {string|string[]} role Permissões necessárias
 * @param {boolean} canShot
 * @return {boolean}
 */
const verifyCanShot = (role, canShot = true) => {
	const CANNOT_SHOT_PROTECT = ['campaigns', 'reports.create'];

	if (canShot) return true;
	
	for (let i = 0; i < CANNOT_SHOT_PROTECT.length; i++) {
		const pm = CANNOT_SHOT_PROTECT[i];

		if (typeof role === "string") {
			if (role.includes(pm)) {
				return false;
			}
		}
	
		for (let i = 0; i < role.length; i++) {
			const e = role[i];
	
			if (e.includes(pm)) {
				return false;
			}
		}
	}

	return true;
}

/**
 * Verifica se o usuário logado possui as permissões necessárias
 * e retorna um booleano.
 * 
 * Usuários do tipo owner possuem todas as permissões.
 * 
 * @param {string|string[]} role Permissões necessárias
 * @param {'and'|'or'} operator Operador de verificação das permissões (Em caso de array)
 * @return {boolean}
 */
export const verifyUserRole = (role, operator = "or") => {
	try {
		const type = store.getters["user/getType"];
		const permissions = store.getters["user/getPermissions"];

		if (role == null) return true;
		if (type == null || permissions == null) return false;

		const user = type === "owner" ? store.getters["user/get"] : store.getters["user/getRoot"];
		if (!(user.expires_at != null && moment(user.expires_at) > moment())) {
			return false;
		}

		if (type === "owner") {
			return verifyCanShot(role, user.can_shot !== false);
		};

		if (!verifyCanShot(role, user.can_shot !== false)) {
			return false;
		}

		if (typeof role === "string") {
			return permissions.includes(role);
		}

		if (operator === "or") {
			for (let i = 0; i < role.length; i++) {
				const e = role[i];

				if (permissions.includes(e)) return true;
			}
		} else {
			const hasPermissions = Array.from(role).filter((e) => permissions.includes(e));
			if (hasPermissions.length === role.length) return true;
		}

		return false;
	} catch (error) {
		console.info(`Falha ao verificar permissão. Motivo: ${error}`);
		return false;
	}
};

export const landRoute = (path) => {
	const rawUrl = process.env.VUE_APP_ROOT_API || "http://localhost:8000/api";
	const url = String(rawUrl).replaceAll(/(https?:\/\/[^/]+).*/g, "$1");

	return `${url}${path}`;
};

export const getFaIconString = (icon, margin = "0 0") => {
	return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="margin: ${margin}" width="14" height="14" color="gray" class="svg-inline--fa fa-${icon.iconName}"><path fill="currentColor" d="${icon.icon[4]}"></path></svg>`;
};

export const disableDateTime = (
	refDate,
	ref = null,
	type = "min",
	alsoTime = false,
	beforeNow = true
) => {
	const now = alsoTime ? moment() : moment().startOf("day");
	const date = alsoTime ? moment(refDate) : moment(refDate).startOf("day");

	if (ref) {
		ref = moment(ref);

		if (alsoTime) {
			if (type === "min") ref.add(1, "hour");
			else ref.subtract(1, "hour");
		}
	}

	return (
		(beforeNow && date < now) ||
		(!beforeNow && date > now) ||
		(((type === "min" && date.isBefore(ref, alsoTime ? null : "day")) ||
			(type === "max" && date.isAfter(ref, alsoTime ? null : "day"))) &&
			ref != null)
	);
};

export const autoScroll = ($event) => {
	const date = moment($event);
	if (date.isSame(moment(), "day") && date.isSame(moment(), "month")) {
		setTimeout(() => {
			document.querySelector('[data-type="hour"] .xmx-time-item:not(.disabled)').click();
		}, 100);
		setTimeout(() => {
			document.querySelector('[data-type="minute"] .xmx-time-item:not(.disabled)').click();
		}, 100);
	}
};

export const truncate = (text, limit) => {
	if (text.length > limit)
		return String(text)
			.substring(0, limit - 2)
			.padEnd(limit, ".");
	return text;
};

/**
 * A partir da label do status, retorna um texto traduzido.
 *
 * @param {string} status
 * @return {string}
 */
export const getStatus = (status) => {
	if (Object.keys(statuses).includes(status)) return statuses[status];
	return "Indefinido";
};
