const SESSION_KEY_NAME = 'CONVO4U_SESSION';

class Session {
    /**
     * Provedor de sessão.
     * @param {object} user
     * @param {object} extraValues
     * @param {string} user._id
     * @param {string} user.name
     * @param {'owner' | 'employee'} user.type
     * @param {string} user.email
     * @param {string} user.profile
     * @param {string} token
     * @param {boolean} remember
     */
    constructor(user = null, token = null, remember = false, extraValues = null) {
        this.user = user;
        this.extraValues = extraValues;
        this.token = token;
        this.remember = remember;
    }

    /**
     * Salva a sessão do usuário em um Cookie em forma de um json codificado em base64.
     */
    save() {
        const rawPayload = { user: this.user, token: this.token };
        const encoded = unescape(encodeURIComponent(JSON.stringify(rawPayload)));
        const payload = btoa(encoded);

        if(this.remember) {
            localStorage.setItem(SESSION_KEY_NAME, payload);
        } else {
            sessionStorage.setItem(SESSION_KEY_NAME, payload);
        }

        return this;
    }

    /**
     * Pega a sessão do usuário do Cookie.
     */
    load() {
        try {
            let rawSession = localStorage.getItem(SESSION_KEY_NAME);

            if(!rawSession)
                rawSession = sessionStorage.getItem(SESSION_KEY_NAME);

            if(!rawSession) return null;

            const unencoded = decodeURIComponent(escape(atob(rawSession)));
            const session = JSON.parse(unencoded);
            this.user = session.user;
            this.token = session.token;
            this.extraValues = session.extraValues;
            return this;
        } catch (error) {
            this.clear();
            return null
        }
    }

    /**
     * Limpa a sessão do usuário no sessionStorage.
     */
    clear() {
        sessionStorage.removeItem(SESSION_KEY_NAME);
        localStorage.removeItem(SESSION_KEY_NAME);
        this.user = null;
        this.token = null;
        this.extraValues = null;
    }

    /**
     * Verifica se está autenticado.
     */
    isAuthenticated() {
        return this.token != null;
    }
}

export { Session };
