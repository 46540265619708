import axios from 'axios';
import { Session } from '@/auth';
import store from '@/store';
import router from '@/router';

const API_BASE_URL = process.env.VUE_APP_ROOT_API;

const statusToResolve = [
    200, 201, 400, 404, 422
];

const api = axios.create({
    baseURL: API_BASE_URL || "http://localhost:8000/api",
    headers: {
        'Accept': 'application/json',
    },
    validateStatus: function (status) {
        return (status >= 200 && status < 300) || statusToResolve.includes(status);
    },
});

api.interceptors.request.use(function (config) {
    const session = new Session();
    session.load();

    if (session.isAuthenticated) {
        config.headers['Authorization'] = `Bearer ${session.token}`
    }

    return config;
});


api.interceptors.response.use(response => response, function (error) {
    if (error.response && error.response.status == 401) {
        store.commit('user/RESET');
        return router.replace({ name: 'auth-login' });
    }

    if (error.response && error.response.status == 503) {
        store.commit('app/SET_MAINTENANCE_MODE', true);
    }

    return Promise.reject(error);
});

export default api;
