<template>
    <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        ref="notificationDropdown"
        right
    >
        <template #button-content>
            <feather-icon
                :badge="pagination.total"
                badge-classes="bg-dark-orange"
                class="text-body"
                icon="BellIcon"
                size="21"
            />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
            </div>
        </li>

        <div v-if="loading.notification" class="position-relative text-center">
            <loading />
            <p class="my-5">Carregando notificações...</p>
        </div>

        <div v-else>
            <div v-if="notifications.length > 0">
                <!-- Notifications -->
                <div
                    class="scrollable-container media-list scroll-area overflow-auto"
                    @scroll.passive="onScroll"
                >
                    <!-- Account Notification -->
                    <span
                        role="button"
                        v-for="notification in notifications"
                        :key="`notification-item-${notification.id}`"
                        @click.prevent.stop="() => markAsRead(notification)"
                        :disabled="notification.data.redirect == null"
                    >
                        <b-media>
                            <template #aside>
                                <b-avatar
                                    size="32"
                                    variant="primary"
                                    icon="bell-fill"
                                    :src="notification.data.image"
                                />
                            </template>
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p class="media-heading">
                                        <span class="font-weight-bolder">
                                            {{ notification.data.title }}
                                        </span>
                                    </p>
                                    <small class="notification-text">
                                        {{ notification.data.content }}
                                    </small>
                                </div>
                                <small class="text-secondary text-nowrap">{{
                                    dateToString(notification.created_at)
                                }}</small>
                            </div>
                        </b-media>
                    </span>
                    <div
                        v-if="loading.more"
                        class="d-flex align-items-center justify-content-center py-3"
                    >
                        <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                        ></span>
                    </div>
                </div>

                <!-- Cart Footer -->
                <li class="dropdown-menu-footer">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        block
                        @click.prevent.stop="markAllAsRead"
                        :disabled="loading.mark"
                    >
                        <p v-if="!loading.mark" class="m-0 text-center">
                            Marcar todas como lidas
                        </p>
                        <span
                            v-else
                            class="spinner-border spinner-border-sm text-light"
                            role="status"
                        ></span>
                    </b-button>
                </li>
            </div>
            <div
                v-else
                class="d-flex flex-column justify-content-center align-items-center p-5"
            >
                <font-awesome-icon icon="fa-solid fa-bell" size="lg" />
                <p class="m-0 mt-3 text-center">
                    <b>Nenhuma notificação encontrada</b> <br />
                    As atualizações e informações adicionais serão mostradas
                    aqui.
                </p>
            </div>
        </div>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BMedia,
    BAvatar,
    BButton,
    BIconBellFill,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import Loading from "@/views/components/Loading";
import moment from "moment";

export default {
    components: {
        BNavItemDropdown,
        BMedia,
        BAvatar,
        BButton,
        Loading,
        // eslint-disable-next-line vue/no-unused-components
        BIconBellFill,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            pagination: { total: 0 },
            notifications: [],
            loading: {
                notification: false,
                mark: false,
                more: false,
            },
        };
    },
    computed: {
        session() {
            return this.$store.state.user.session;
        },
    },
    created() {
        this.getUnreadNotifications();
    },
    watch: {
        "$store.state.ws.status"() {
            if (this.$store.getters["ws/connected"]) {
                this.$store.dispatch("ws/ListenNotifications", {
                    id: this.session.user._id,
                    func: (object) => {
                        this.notifications.unshift(object);
                        this.pagination.total += 1;
                    },
                });
            }
        },
    },
    methods: {
        getUnreadNotifications(page = 1) {
            if (page == 1) this.loading.notification = true;
            else this.loading.more = true;

            api.get("/notifications", {
                params: { readed: -1, limit: 6, page },
            })
                .then((response) => {
                    const { type, body, misc } = response.data;

                    if (type == "success") {
                        this.pagination = misc;
                        let rawData = [...this.notifications, ...body];
                        let ids = [...new Set(rawData.map((e) => e.id))];
                        let data = ids.map((e) =>
                            rawData.find((v) => v.id == e)
                        );
                        return (this.notifications = data);
                    }
                })
                .finally(() => {
                    this.loading.notification = false;
                    this.loading.more = false;
                });
        },
        markAsRead(notification) {
            this.loading.mark = true;
            api.patch(`/notifications/${notification.id}`)
                .then((response) => {
                    const { type } = response.data;

                    if (type != "success") return;

                    this.pagination.total -= 1;
                    const index = this.notifications.findIndex(
                        (e) => e.id == notification.id
                    );
                    this.notifications.splice(index, 1);

                    if (notification.data.redirect != null) {
                        return this.$router
                            .push(notification.data.redirect)
                            .then(() =>
                                this.$refs.notificationDropdown.hide(true)
                            );
                    }
                })
                .catch((e) => console.log(e))
                .finally(() => (this.loading.mark = false));
        },
        markAllAsRead() {
            this.loading.mark = true;
            api.put("/notifications")
                .then((response) => {
                    const { type } = response.data;

                    if (type == "success") {
                        this.pagination.total = 0;
                        return (this.notifications = []);
                    }
                })
                .finally(() => (this.loading.mark = false));
        },
        onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            const page = this.pagination.current_page;
            if (
                !this.loading.more &&
                scrollTop + clientHeight >= scrollHeight - 50 &&
                page < this.pagination.last_page
            ) {
                this.getUnreadNotifications(page + 1);
            }
        },
        dateToString(rawDate) {
            try {
                const time = moment().subtract(moment(rawDate)).unix();
                const breakpoints = [
                    [365 * 24 * 60 * 60, "y"],
                    [60 * 24 * 60 * 60, "meses"],
                    [7 * 24 * 60 * 60, "sem"],
                    [24 * 60 * 60, "d"],
                    [60 * 60, "h"],
                    [60, "m"],
                    [-1, "s"],
                ];

                const test = breakpoints.find(([b]) => time > b);
                const [pack, sufix] = test;
                return Math.abs(Math.floor(time / pack)) + sufix;
            } catch (e) {
                return "agora";
            }
        },
    },
};
</script>
