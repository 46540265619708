<template>
    <div class="d-flex flex-column h-100 w-100 authentication">
        <auth-header></auth-header>
        <main class="authentication-body">
            <section class="w-100 h-100">
                <slot></slot>
            </section>
        </main>
        <auth-footer></auth-footer>
    </div>
</template>

<script>
import AuthHeader from './Header.vue';
import AuthFooter from './Footer.vue';
export default {
    components: {
        AuthFooter,
        AuthHeader
    }
}
</script>