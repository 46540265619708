import { mutations } from "./mutations"

export const reportStoreModel = () => {
    return {
        saved: null
    }
}

export default {
    namespaced: true,
    state: reportStoreModel(),
    mutations: mutations,
}
