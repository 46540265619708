import Vue from "vue";
import Vuex from "vuex";

// Módulos
import user from "./modules/user";
import app from "./modules/app";
import form from "./modules/form";
import appConfig from "./modules/app-config";
import ws from "./modules/ws";
import chat from "./modules/chat";
import report from "./modules/report";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        user,
        form,
        ws,
        chat,
        report
    },
    mutations: {
      CLEAR_STATES: () => {
          this.commit('forms/RESET');
          this.commit('user/RESET');
          this.dispatch('ws/Disconnect');
      }
    },
    strict: process.env.DEV,
});
