<template>
    <header class="header py-2">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div id="navbar__header" class="container">
                <a class="navbar-brand" :href="$landRoute('/')">
                    <img width="160" src="/assets/imgs/logo_convo4u.svg" alt="Logo Convo4U">
                </a>
                <span v-b-toggle:nav-collapse type="button" class="d-lg-none text-blue">
                    <font-awesome-icon icon="fas fa-bars" size="lg" style="width: 25px;height: 25px;" />
                </span>

                <b-collapse id="nav-collapse" is-nav>
                    <ul class="navbar-nav ml-auto align-items-center">
                        <li class="nav-item mr-2">
                            <a class="nav-link" :href="$landRoute('/')">Home</a>
                        </li>
                        <li class="nav-item mx-1 #">
                            <a class="nav-link" :href="$landRoute('/crm')">CRM 3.0</a>
                        </li>
                        <li class="nav-item mx-1 #">
                            <a class="nav-link" :href="$landRoute('/plataform')">A Plataforma</a>
                        </li>
                        <li class="nav-item ml-1 mr-3">
                            <a class="nav-link" :href="$landRoute('/cases')">Cases</a>
                        </li>
                        <li class="nav-item d-flex">
                            <router-link class="mr-3" custom :to="{ name: 'auth-login' }" v-slot="{ navigate, href }">
                                <a
                                    class="btn btn-outline-primary w-1" 
                                    type="button"
                                    :href="href"
                                    @click="navigate"
                                >
                                    Entrar
                                </a>
                            </router-link>
                            <router-link custom :to="{ name: 'auth-register' }" v-slot="{ navigate, href }">
                                <a
                                    class="btn btn-primary w-1" 
                                    type="button"
                                    :href="href"
                                    @click="navigate"
                                >
                                    Cadastrar-se
                                </a>
                            </router-link>
                        </li>
                    </ul>
                </b-collapse>

            </div>
        </nav>
    </header>
</template>

<script>
import { BCollapse } from 'bootstrap-vue';

export default {
    components: {
        BCollapse
    }
}
</script>