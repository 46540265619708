export const mutations = {
    SET_MULTI: (state, forms) => {
        Object.assign(state, { items: forms || [] });
    },
    SET_ONE: (state, form) => {
      state.items.unshift(...form);
    },
    RESET: (state) => {
        state.items = [];
    }
}
