<template>
    <div class="navbar-container d-flex content align-items-center">
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
            <b-navbar-nav class="nav">
                <b-nav-item>
                    <img src="/assets/imgs/logo.png" class="img-fluid" alt="Convo4u Logo" width="150">
                </b-nav-item>
            </b-navbar-nav>
        </div>
        <b-navbar-nav class="nav align-items-center ml-auto">
            <notification-dropdown />
            <user-dropdown />
        </b-navbar-nav>
    </div>
</template>

<script>
import { BNavbarNav, BNavItem } from "bootstrap-vue";
import NotificationDropdown from "@/views/layouts/AppLayout/components/NotificationDropdown.vue";
import UserDropdown from "@/views/layouts/AppLayout/components/UserDropdown.vue";

export default {
    components: {
        BNavbarNav,
        BNavItem,
        NotificationDropdown,
        UserDropdown
    },
};
</script>
