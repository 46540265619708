<template>
    <div
        class="sidebar-menu bg-white h-md-100 d-flex flex-md-column align-items-start align-items-md-center justify-content-between px-3 py-md-4 py-2 mb-1 mb-md-0"
    >
        <div></div>

        <div class="d-flex flex-md-column align-items-center w-100">
            <template v-for="(item, index) in navItems.middle">
                <router-link
                :key="`sidebar-nav-item-${index}`"
                v-slot="{ href, navigate }"
                :to="{ name: item.route }"
                class="my-md-2 mx-2 mx-md-0"
                custom
                v-if="$role(item.role, 'or')"
            >
                <a :href="href" @click="navigate" class="w-75">
                    <span
                        class="menu-link d-flex flex-column align-items-center "
                        role="button"
                    >
                        <div
                            :class="`${isActiveRoute(item) ? 'bg-blue text-white' : 'text-dark'} square rounded d-flex align-items-center justify-content-center w-100`"
                        >
                            <div class="position-relative">
                                <img v-if="isActiveRoute(item)" :src="`/assets/imgs/icons/menu/${item.icon}-white.svg`" alt="">
                                <img v-else :src="`/assets/imgs/icons/menu/${item.icon}.svg`" alt="">
                                <span
                                    v-if="
                                        item.bagde &&
                                        item.bagde == 'unreaded' &&
                                        unreaded != null
                                    "
                                    class="position-absolute bagde-top-right translate-middle badge rounded-pill bg-dark-orange"
                                >
                                    {{ unreaded }}
                                </span>
                            </div>
                        </div>
                        <span
                            :class="`text-truncate text-decoration-none d-none d-md-block mt-1 ${
                                active == item.route ? 'text-blue' : ''
                            }`"
                            >{{ item.title }}</span
                        >
                    </span>
                </a>
            </router-link>
            </template>
        </div>

        <div
            class="d-flex flex-md-column align-items-start align-items-md-center w-100 justify-content-end"
        >
            <router-link
                v-for="(item, index) in navItems.end"
                :key="`sidebar-nav-item-bottom-${index}`"
                v-slot="{ navigate, href }"
                :to="{ name: item.route }"
                custom
            >
                <a :href="href" @click="navigate" class="m-0">
                    <span
                        v-if="$role(item.role, 'or')"
                        class="menu-link d-flex flex-column align-items-center m-0"
                        role="button"
                    >
                        <div
                            :class="`${
                                active == item.route
                                    ? 'bg-blue text-white'
                                    : 'text-dark'
                            } square rounded d-flex align-items-center justify-content-center w-100`"
                        >
                            <feather-icon :icon="item.icon" size="25" />
                        </div>
                    </span>
                </a>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        active() {
            return this.$route.name || "";
        },
        unreaded() {
            const unreaded = this.$store.getters["chat/getUnreadedCount"]();

            if (unreaded == 0) return null;
            return unreaded > 99 ? "99+" : unreaded;
        },
    },
    watch: {
        "$store.state.chat.unreadedMessages"() {
            this.$forceUpdate();
        },
    },
    data() {
        return {
            navItems: {
                middle: [
                    {
                        title: "Cadastros",
                        route: "register",
                        alias: ['forms'],
                        icon: "users",
                        role: [
                            "forms.get",
                            "answers.get",
                            "customers.get",
                            "forms.create",
                        ],
                    },
                    {
                        title: "Campanhas",
                        route: "campaigns",
                        alias: ['segmentation', 'shots'],
                        icon: "campaigns",
                        role: [
                            "campaigns.get",
                            "campaigns.create",
                            "campaigns.update",
                            "campaigns.delete",
                        ],
                    },
                    {
                        title: "Respostas",
                        route: "responses",
                        icon: "responses",
                        role: [
                            "answers.get",
                            "answers.create",
                            "answers.update",
                            "answers.delete",
                        ],
                    },
                    {
                        title: "Mensagens",
                        route: "chats",
                        icon: "messages",
                        bagde: "unreaded",
                        role: ["chats.get"],
                    },
                    {
                        title: "Relatórios",
                        route: "reports",
                        icon: "reports",
                        role: ["reports.get"],
                    },
                ],
                end: [
                    {
                        route: "settings",
                        icon: "SettingsIcon",
                    },
                ],
            },
        };
    },
    methods: {
        isActiveRoute(item) {
            if(this.active.includes(item.route)) return true;

            if(!item.alias || item.alias.length == 0) return false;

            for (let i = 0; i < item.alias.length; i++) {
                const alias = item.alias[i];

                if(this.active.includes(alias)) return true;
            }
            return false;
        },
    }
};
</script>
