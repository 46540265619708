import { actions } from "./actions"
import { getters } from "./getters"
import { mutations } from "./mutations"

export const userStoreModel = () => {
    return {
        user: {
            _id: null,
            name: null,
            profile: null,
            type: null,
        },
        token: null,
        session: null,
        extraValues: {
            additional_phone_price: null,
            additional_agent_price: null
        },
    }
}

export default {
    namespaced: true,
    state: userStoreModel(),
    getters: getters,
    actions: actions,
    mutations: mutations,
}
