var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar-menu bg-white h-md-100 d-flex flex-md-column align-items-start align-items-md-center justify-content-between px-3 py-md-4 py-2 mb-1 mb-md-0"},[_c('div'),_c('div',{staticClass:"d-flex flex-md-column align-items-center w-100"},[_vm._l((_vm.navItems.middle),function(item,index){return [(_vm.$role(item.role, 'or'))?_c('router-link',{key:`sidebar-nav-item-${index}`,staticClass:"my-md-2 mx-2 mx-md-0",attrs:{"to":{ name: item.route },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('a',{staticClass:"w-75",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-link d-flex flex-column align-items-center",attrs:{"role":"button"}},[_c('div',{class:`${_vm.isActiveRoute(item) ? 'bg-blue text-white' : 'text-dark'} square rounded d-flex align-items-center justify-content-center w-100`},[_c('div',{staticClass:"position-relative"},[(_vm.isActiveRoute(item))?_c('img',{attrs:{"src":`/assets/imgs/icons/menu/${item.icon}-white.svg`,"alt":""}}):_c('img',{attrs:{"src":`/assets/imgs/icons/menu/${item.icon}.svg`,"alt":""}}),(
                                    item.bagde &&
                                    item.bagde == 'unreaded' &&
                                    _vm.unreaded != null
                                )?_c('span',{staticClass:"position-absolute bagde-top-right translate-middle badge rounded-pill bg-dark-orange"},[_vm._v(" "+_vm._s(_vm.unreaded)+" ")]):_vm._e()])]),_c('span',{class:`text-truncate text-decoration-none d-none d-md-block mt-1 ${
                            _vm.active == item.route ? 'text-blue' : ''
                        }`},[_vm._v(_vm._s(item.title))])])])]}}],null,true)}):_vm._e()]})],2),_c('div',{staticClass:"d-flex flex-md-column align-items-start align-items-md-center w-100 justify-content-end"},_vm._l((_vm.navItems.end),function(item,index){return _c('router-link',{key:`sidebar-nav-item-bottom-${index}`,attrs:{"to":{ name: item.route },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate, href }){return [_c('a',{staticClass:"m-0",attrs:{"href":href},on:{"click":navigate}},[(_vm.$role(item.role, 'or'))?_c('span',{staticClass:"menu-link d-flex flex-column align-items-center m-0",attrs:{"role":"button"}},[_c('div',{class:`${
                            _vm.active == item.route
                                ? 'bg-blue text-white'
                                : 'text-dark'
                        } square rounded d-flex align-items-center justify-content-center w-100`},[_c('feather-icon',{attrs:{"icon":item.icon,"size":"25"}})],1)]):_vm._e()])]}}],null,true)})}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }