<template>
    <div class="max-vh-100 vh-100 overflow-hidden d-flex flex-column">
        <b-navbar
            :toggleable="false"
            class="header-navbar navbar navbar-shadow align-items-center"
        >
            <slot
                name="navbar"
                :navbarTypeClass="[
                    'header-navbar navbar navbar-shadow align-items-center',
                ]"
            >
                <app-navbar />
            </slot>
        </b-navbar>

        <main
            class="d-flex flex-column flex-md-row flex-grow-1 w-100"
            style="height: 50vh"
        >
            <side-navbar></side-navbar>
            <import-progress-toast
                v-for="watcher in watchingImports"
                :key="`toast-${watcher.batch.id}`"
                :batch-id="watcher.batch.id"
                :form="watcher.form"
                :size="watcher.batch.total"
            />

            <transition mode="out-in">
                <div
                    class="p-4 px-sm-5 py-sm-4 flex-grow-1 d-flex flex-column"
                    style="overflow-x: hidden; overflow-y: auto"
                >
                    <transition mode="out-in">
                        <template v-for="(index, name) in $scopedSlots">
                            <slot :name="name"></slot>
                        </template>
                    </transition>
                </div>
            </transition>
        </main>
    </div>
</template>

<script>
import { BNavbar } from "bootstrap-vue";
import SideNavbar from "@/views/layouts/AppLayout/SideNavbar.vue";
import AppNavbar from "@/views/layouts/AppLayout/Navbar.vue";
import ImportProgressToast from "@/views/components/ImportProgressToast.vue";

export default {
    components: {
        BNavbar,
        SideNavbar,
        AppNavbar,
        ImportProgressToast,
    },
    data() {
        return {
            watchingImports: [],
        };
    },
    mounted() {
        this.$root.$on("pushWatchImport", (data) => {
            this.watchingImports.push(data);
        });

        if (this.$role(["chats.get"]))
            this.$store.dispatch("chat/GetUnreadedMessages");

        this.$store.dispatch("ws/Connect", {
            token: this.$store.state.user.session.token,
        });
    },
};
</script>
