import { getters } from "./getters";
import { mutations } from "./mutations";

export const appStoreModel = () => {
    return {
        windowWidth: 0,
        shallShowOverlay: false,
        maintenanceMode: false
    }
};

export default {
    namespaced: true,
    state: appStoreModel(),
    getters: getters,
    mutations: mutations,
}
