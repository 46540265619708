import Echo from 'laravel-echo';
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';
import app from '@/main';
import router from '@/router';
import api from '@/api';

export const actions = {
    Connect: ({ commit, state, rootState }, { token }) => {
        if(!state.echo) {
            state.echo = new Echo({
                broadcaster: 'pusher',
                key: process.env.VUE_APP_PUSHER_APP_KEY,
                cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER || 'mt1',
                wsHost: process.env.VUE_APP_PUSHER_APP_HOST || '127.0.0.1',
                wsPort: process.env.VUE_APP_PUSHER_APP_PORT || 6001,
                disableStats: false,
                forceTLS: process.env.VUE_APP_PUSHER_APP_SSL || false,
                encrypted: process.env.VUE_APP_PUSHER_APP_SSL || false,
                authEndpoint: process.env.VUE_APP_PUSHER_APP_AUTH_ENDPOINT || 'http://localhost:8000/broadcasting/auth',
                enabledTransports: ['ws', 'wss'],
                disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'],
                auth: {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                },
            })
            state.echo.connector.pusher.connection.bind('connected', () => {
                commit('SET_STATUS', true);

                if(app.$role(['chats.get'])) {
                    const id = rootState.user.session.user._id;

                    const processUpdateUnreaded = ({ total_count, contact_count, contact_id  }) => {
                        const contacts = rootState.chat.contacts || [];
                        const index = contacts.findIndex(e => e._id == contact_id);

                        commit('chat/SET_UNREADED_MESSAGES', total_count, { root: true });
                        if(index >= 0) {
                            let contact = rootState.chat.contacts[index];
                            Object.assign(contact, { unreaded_messages: contact_count });
                            commit('chat/SET_CONTACT', contact, { root: true });
                        }
                    }

                    const processUpdateMessage = ({ contact, message }) => {
                        const contacts = rootState.chat.contacts || [];
                        const index = contacts.findIndex(e => e._id == contact._id);

                        if((contact.status == 'finished' && index >= 0) || contact.status != 'finished') {
                            commit('chat/ADD_MESSAGE', message, { root: true });
                            commit('chat/SET_CONTACT', contact, { root: true });
                        }
                    }

                    const processUpdateContact = ({ contact }) => {
                        const user = rootState.user.user;
                        const group = user.group;
                        const isSupervisor = group && group.is_supervisor;
                        const contacts = rootState.chat.contacts || [];
                        const index = contacts.findIndex(e => e._id == contact._id);
                        
                        if(
                            (isSupervisor && contact.status == 'supervisor') || 
                            (!isSupervisor && !['finished', 'supervisor'].includes(contact.status)) || 
                            user.type == 'owner' && contact.status != 'finished'
                        )
                            return commit('chat/SET_CONTACT', contact, { root: true });
                        
                        if(index === -1) return;

                        commit('chat/REMOVE_CONTACT', contact._id, { root: true });
                        const route = router.currentRoute;
                        if(route.name == 'chats/show' && route.params.phone == contact.phone)
                            router.replace({name: 'chats'});
                    }

                    const processUpdateSuggest = ({ contact }) => {
                        const id = contact._id;
                        api.get(`/chats/${id}/visible`)
                        .then(res => {
                            const { type, body } = res.data;
                
                            if(type != 'success') return;

                            if(body)
                                return commit('chat/SET_CONTACT', contact, { root: true });
                            
                            commit('chat/REMOVE_CONTACT', contact._id, { root: true });
                            const route = router.currentRoute;
                            if(route.name == 'chats/show' && route.params.phone == contact.phone)
                                router.replace({name: 'chats'});
                        })
                        .catch((e) => console.log(e));
                    }
    
                    state.echo.private(`App.Models.User.${id}`)
                    .listen('.MessageCreated', processUpdateMessage)
                    .listen('.MessageUpdated', processUpdateMessage)
                    .listen('.SuggestAnswerCreated', processUpdateSuggest)
                    .listen('.SuggestAnswerDeleted', processUpdateSuggest)
                    .listen('.ContactUpdated', processUpdateContact)
                    .listen('.UnreadedMessagesUpdated', processUpdateUnreaded);
                }
            });
            state.echo.connector.pusher.connection.bind('disconnected', () => {
                commit('SET_STATUS', false);
            });
        }
        state.echo.connector.pusher.connect();
    },
    Disconnect: ({ state }) => {
        if(!state.echo) return;
        state.echo.disconnect()
    },
    ListenNotifications: ({ state }, { id, func }) => {
        state.echo.private(`App.Models.User.${id}`)
        .notification(func)
    },
    ListenEvent: ({ state }, { id, event, func }) => {
        state.echo.private(`App.Models.User.${id}`)
        .listen(event, func)
    },
    ListenEventChannel: ({ state }, { channel, event, func, onSubscribe }) => {
        return state.echo.private(channel)
        .listen(event, func)
        .on("pusher:subscription_succeeded", onSubscribe)
    },
}
