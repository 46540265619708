import { $themeConfig } from "@themeConfig"
import { mutations } from "./mutations"

export const appConfigStoreModel = () => {
    return {
        layout: {
            routerTransition: $themeConfig.layout.routerTransition,
            type: $themeConfig.layout.type,
            contentWidth: $themeConfig.layout.contentWidth,
            menu: {
                hidden: $themeConfig.layout.menu.hidden,
            },
            navbar: {
                type: $themeConfig.layout.navbar.type,
                backgroundColor: $themeConfig.layout.navbar.backgroundColor,
            },
            footer: {
                type: $themeConfig.layout.footer.type,
            },
        },
    }
}

export default {
    namespaced: true,
    state: appConfigStoreModel(),
    getters: {},
    actions: {},
    mutations: mutations,
}
