import { actions } from "./actions"
import { getters } from "./getters"
import { mutations } from "./mutations"

export const wsStoreModel = () => {
    return {
        echo: null,
        status: 'disconnected'
    }
}

export default {
    namespaced: true,
    state: wsStoreModel(),
    getters: getters,
    actions: actions,
    mutations: mutations,
}
