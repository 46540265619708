import api from "@/api";

export const actions = {
    index({commit}, payload) {
        let with_customers = false;
        let has_whatsapp = false;
        if (payload.with_customers) with_customers = payload.with_customers
        if (payload.has_whatsapp) has_whatsapp = payload.has_whatsapp

        return new Promise((resolve) => {
            api.get(`/forms/`, {
                headers: payload.auth.headers,
                params: {
                    with_customers: with_customers,
                    has_whatsapp: has_whatsapp
                }
            })
            .then(response => {
                commit('SET_MULTI', response.data.body);
            })
            .catch(error => {
                commit('RESET', error);
            })
            .finally(() => resolve());
        });
    }
}
