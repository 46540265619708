import {
    VBTooltipPlugin,
    ToastPlugin,
    ModalPlugin,
    SidebarPlugin,
} from "bootstrap-vue";
import { getFaIconString, landRoute, verifyUserRole } from "./api/functions";
import VueCompositionAPI from "@vue/composition-api";
import VueSweetalert2 from "vue-sweetalert2";
import router from "./router";
import App from "./App.vue";
import store from "./store";
import VueRouter from "vue-router";
import Vue from "vue";
import "vue-multiselect/dist/vue-multiselect.min.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import {
    faWhatsapp,
    faTelegram,
    faPix,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import {
    faSitemap,
    faCaretDown,
    faCaretUp,
    faHammer,
    faCheck,
    faTag,
    faMoneyBill,
    faRotateRight,
    faCircleInfo,
    faComments,
    faXmark,
    faEyeDropper,
    faQuestion,
    faPlus,
    faLink,
    faMinus,
    faComment,
    faArrowUpFromBracket,
    faFile,
    faClose,
    faPenToSquare,
    faTrashCan,
    faBold,
    faItalic,
    faUnderline,
    faStrikethrough,
    faPaperclip,
    faImage,
    faChevronUp,
    faBell,
    faMagnifyingGlass,
    faCircleXmark,
    faEllipsisVertical,
    faVideo,
    faFaceSmile,
    faSave,
    faEnvelope,
    faQrcode,
    faRotate,
    faRightLeft,
    faChevronLeft,
    faChevronDown,
    faNetworkWired,
    faBookmark,
    faPaperPlane,
    faBars,
    faRobot,
    faBoltLightning,
    faEye,
    faEyeSlash,
    faCreditCard,
    faBan,
    faBarcode,
    faPlay,
    faFilePdf,
    faDownload,
    faEllipsis,
    faPencil,
    faPause,
    faVolumeHigh,
    faVolumeDown,
    faGear,
    faClock,
    faList,
    faAddressBook,
    faTriangleExclamation,
    faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";

library.add(
    faBoltLightning,
    faBan,
    faSitemap,
    faClock,
    faPaperPlane,
    faList,
    faAddressBook,
    faEllipsis,
    faQrcode,
    faFilePdf,
    faPlay,
    faRotate,
    faPix,
    faBookmark,
    faCreditCard,
    faLink,
    faBarcode,
    faCopy,
    faMoneyBill,
    faBars,
    faSave,
    faTag,
    faEnvelope,
    faComment,
    faCircleXmark,
    faArrowUpFromBracket,
    faPenToSquare,
    faChevronLeft,
    faQuestion,
    faHammer,
    faEllipsisVertical,
    faTrashCan,
    faClose,
    faChevronDown,
    faChevronUp,
    faCaretDown,
    faCaretUp,
    faWhatsapp,
    faBell,
    faCheck,
    faRotateRight,
    faCircleInfo,
    faComments,
    faMagnifyingGlass,
    faTelegram,
    faXmark,
    faEyeDropper,
    faPlus,
    faMinus,
    faFile,
    faBold,
    faDownload,
    faItalic,
    faUnderline,
    faStrikethrough,
    faPaperclip,
    faImage,
    faVideo,
    faFaceSmile,
    faRightLeft,
    faNetworkWired,
    faRobot,
    faEye,
    faEyeSlash,
    faPencil,
    faPause,
    faVolumeHigh,
    faVolumeDown,
    faGear,
    faTriangleExclamation,
    faVolumeXmark,
);

Vue.use(VueRouter);

Vue.mixin({
    computed: {
        user() {
            return this.$store.getters["user/get"];
        },
        auth() {
            return this.$store.getters["user/getAuth"];
        },
        extraValues() {
            return this.$store.getters["user/getExtraValues"]
        }
    },
});

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

// Quill Editor
import VueQuillEditor, { Quill } from "vue-quill-editor";
// Adiciona suporte a emojis (editor sem emojis não é editor 😏)
import QuillEmoji from "quill-emoji";
// Módulos do quill
import "quill/dist/quill.snow.css";
import "quill-paste-smart";
import "quill-emoji/dist/quill-emoji.css";
const icons = Quill.import("ui/icons");

icons["bold"] = getFaIconString(faBold);
icons["italic"] = getFaIconString(faItalic);
icons["strike"] = getFaIconString(faStrikethrough);
icons["image"] = getFaIconString(faImage);
icons["file"] = getFaIconString(faPaperclip, "0 2");
icons["video"] = getFaIconString(faVideo);
icons["link"] = getFaIconString(faLink);

Quill.register({
    "modules/emoji": QuillEmoji,
});
Vue.use(VueQuillEditor);

// Global Components
import "./global-components";
import "@axios";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";
import "vue2-datepicker/index.css";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(SidebarPlugin);
Vue.use(VBTooltipPlugin);

// Vue The Mask
import VueTheMask, { mask } from "vue-the-mask";
Vue.use(VueTheMask);
Vue.directive("mask", (el, binding) => {
    if (!binding.value) return;
    mask(el, binding);
});

// Moment
import moment from "moment";
Vue.prototype.moment = moment;

// SweetAlert2
Vue.use(VueSweetalert2);

// Funções Globais
Vue.prototype.$role = verifyUserRole;
Vue.prototype.$landRoute = landRoute;

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

require("dotenv").config();

var app = new Vue({
    store,
    Event,
    render: (h) => h(App),
});


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {
    });
};

async function config() {
    await store.dispatch("user/loadSession");

    app = new Vue({
        store,
        Event,
        router,
        render: (h) => h(App),
    }).$mount("#app");
}

config();

export default app;
