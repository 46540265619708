export const mutations = {
    UPDATE_WINDOW_WIDTH(state, val) {
        state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
        state.shallShowOverlay =
            val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_MAINTENANCE_MODE(state, val) {
        state.maintenanceMode = val;
    },
}
