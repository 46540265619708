import { Session } from "@/auth";
import { userStoreModel } from ".";

export const mutations = {
    SET(state, data) {
        const user  = JSON.parse(JSON.stringify(data));
        Object.assign(state, { user });
    },
    SET_TOKEN(state, token) {
        const session = new Session().load();
        session.token = token;
        session.save();

        state.token = token;
        state.session = Object.assign(state.session, { token });
    },
    SET_SESSION(state, session) {
        state.session = session;
        state.user = session.user;
        state.token = session.token;
        state.extraValues = session.extraValues;
    },
    RESET(state) {
        new Session().clear();
        Object.assign(state, userStoreModel())
    },
}
