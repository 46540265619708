export const getters = {
    unreaded: (state) => {
        return state.unreadedMessages
    },
    getUnreadedCount: (state) => () => {
        return state.unreadedMessages
    },
    getContacts: (state) => {
        return state.contacts;
    }
}
